import React, { useState } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';


import logo from '../images/FILM_POSITIVE_logo_web_80x92.png';

const mapStyles = {
    // width: 960,
    width: '100%',
    // height: 400,
    height: 0,
    paddingTop: '56%',
    background: 'rgba(12,12,12,.8)',
    
};

const location = {
    address: 'Budapest, Völgy u. 19, 1021 Hungary',
    lat: 47.5346195,
    lng: 18.9758658,
}

const styleArray = [

   {
    featureType: "poi.business",
    elementType: "labels",
    stylers: [
      { visibility: "off" }
    ]
  },

        { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
        { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
        { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#263c3f" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#6b9a76" }],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#38414e" }],
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "#212a37" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9ca5b3" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#746855" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [{ color: "#1f2835" }],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [{ color: "#f3d19c" }],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ color: "#2f3948" }],
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#17263c" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#515c6d" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#17263c" }],
        },
];


export const MapContainer = (props) => {
    return (
      <Map
        google={props.google}
        zoom={16}
        style={mapStyles}
        initialCenter={
          {
            lat: 47.5346195,
            lng: 18.9758658
          }
        }
        defaultCenter={
          {
            lat: 47.5346195,
            lng: 18.9758658
          }
        }
        
        disableDefaultUI={true}
        mapTypeControl={true}
        streetViewControl={true}
        styles={styleArray}

        
      >
        <Marker
            lat={47.5346195}
            lng={18.9758658}
            icon={logo}
            text={"Film Positive"}
            name="Film positive"
        />
      </Map>
    )
}


export default GoogleApiWrapper({
  apiKey: 'AIzaSyBKOpUxUB9uojvZIX9MlHnu7Iv3gUYveoo'
})(MapContainer);