import React from 'react';
import {
  Link,
  useI18next,
} from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/about-us-content"


const AboutUsPage = () => {
    const {t} = useI18next();
    return (
        <Layout>
            <SEO title={t("ABOUT_US.ABOUT_US")} />
            <Content />
        </Layout>
    )
}

export default AboutUsPage;