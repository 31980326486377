import React, { useRef, useEffect } from 'react';
import Grow from '@material-ui/core/Grow';
import { Typography } from '@material-ui/core';
import { graphql, useStaticQuery} from "gatsby"
import {
  Link,
  useI18next,
} from "gatsby-plugin-react-i18next"

import gsap from "gsap";

import Img from "gatsby-image"
import styled from "styled-components"


import budapest from '../images/budapest-at-night.jpg'
import hungaryTaxRebate from '../images/hungary-tax-rebate.png'

import Map from './map'

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 55px;
    width: 100vw;
    height: 100vh;
    // background-image: url(${budapest});
    // background-repeat: no-repeat;
    // background-position: center;
    
    
    &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    
    &::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%2321409a' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")
    }
    
`

const ContentWrapper = styled.div`
    position: relative;
    margin-top: 150px;
    @media screen and (max-width: 1025px) {
        margin-top: 40vh;
    }
`

const ContentPanel = styled.div`
    position: relative;
    max-width: 1280px;
    min-height: inherit;
    width: 61.8%;
    width: 65%;
    width: 70%;
                                width: 960px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 85px;
    
    // background: rgba(255,255,255,0.8);

    // height: 100vh;
    width: auto;

    @media screen and (max-width: 1025px) {
        margin-left: 0;
        height: auto;
    }
   
`

const ContentInner = styled.div`
    padding: 32px;

    @media screen and (max-width: 1025px) {
        padding: 0;
        width: 100%;
    }
    
    h1, h2, p {
        font-family: 'futura-pt', sans-serif;
        padding: 16px 32px;
        margin-bottom: 0;
        overflow: hidden;
        span {
            display: inline-block;
        }
    }

    h1 {
        @media screen and (max-width: 1025px) {
            font-size: 28px;
        }

    }

    h2 {
        @media screen and (max-width: 1025px) {
            font-size: 24px;
        }
        
    }
    
    p {
        font-size: 24px;
        line-height: 1.4;
        white-space: pre-wrap;

        @media screen and (max-width: 1025px) {
            font-size: 18px;
        }
        
        &.padding-top-zero {
            padding-top: 0;
            
        }
        
        &.padding-bottom-zero {
            padding-bottom: 0;
        }

        /* @media screen and (max-width: 1025px) {
            font-size: 16px;
        } */
    }
    
    img {
        margin-bottom: 0;
    }
    
    .inner-group {
        position: relative;
        margin-bottom: 4px;
        background: rgba(255, 217, 1, 0.9);
        background: rgba(12,12,12,0.85);
        color: rgba(255,217,1,0.9);
        &.map {
            width: 960px;
            height: 400px;
        }
    }
    
    .inner-divider {
        margin-top: 32px;
    }
    
    .extra-padding {
        padding-bottom: 16px;
    }
    
    .image-wrapper {
        width: 100%;
        display: flex;
        // justify-content: center;
        padding: 12px;
    }
    
    
    
    
    a {
        color: rgba(12,12,12,0.8);
        color: rgba(255,255,255,0.7);
        text-decoration: none;
        
        &:hover {
            color: black;
            color: rgba(255,255,255,1);
        }
    }
`

const StyledImg = styled(Img)`
  @media screen and (max-width: 1025px) {
    height: 100vh;
    width: auto;
  }
   
`

// const businessEnquiries = `Abel Köves
// Mobile: +36 30 650 5454
// abel.koves@filmpositive.hu

// Laszlo Bederna
// mobile: +36 30 9 490 978
// email: laszlo.bederna@filmpositive.hu
// `


const AboutUsContent = () => {
    
    const {t} = useI18next();
    
    
    let content = useRef(null);
    let content2 = useRef(null);
    let content3 = useRef(null);
    let content4 = useRef(null);
    
    useEffect(()=>{
        gsap.from([content.current, content2.current, content3.current, content4.current], {
            y: 100,
            // opacity: 0, 
            duration: 1,
            delay: 0.5,
            stagger: 0.3,
        })
        
    }, [])
    
    const data = useStaticQuery(graphql`
        query {
          file(relativePath: {eq: "post-mortem-day25-werk_XT32616.jpg"}) {
            childImageSharp {
              # Specify the image processing specifications right in the query.
              # Makes it trivial to update as your page's design changes.
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
    `)
    
    return (
        <React.Fragment>
        <Container>
            <StyledImg
                fluid={data.file.childImageSharp.fluid} 
                alt="Post mortem day 25 werk"
            />
        </Container>
        
        <ContentWrapper>
            <ContentPanel>
                <ContentInner>
                    <Grow in={true} {...(true ? { timeout: 1000 } : {})}>
                        <div className="inner-group">
                            <h1><span ref={content}>{t("ABOUT_US.ABOUT_US")}</span></h1>
                        </div>
                    </Grow>
                    <Grow in={true} {...(true ? { timeout: 1500 } : {})}>
                        <div className="inner-group">
                            <h2><span ref={content2}>Film Positive Productions</span></h2>
                        </div>
                    </Grow>
                    <Grow in={true} {...(true ? { timeout: 2000 } : {})}>
                        <div className="inner-group extra-padding" >
                            <p>{t("ABOUT_US.CONTENT")}</p>
                        </div>
                    </Grow>
              
                    <Grow in={true} {...(true ? { timeout: 2500 } : {})}>
                        <div className="inner-group inner-divider">
                            <h2><span ref={content3}>{t("ABOUT_US.NEW_BUSINESS_ENQUIRIES")}</span></h2>
                        </div>
                    </Grow>
                    <Grow in={true} {...(true ? { timeout: 3000 } : {})}>
                        <div className="inner-group">
                            {/* <p className="padding-bottom-zero">{t("ABOUT_US.CONTACT_1")}</p>
                            <p className="padding-top-zero padding-bottom-zero">{t("ABOUT_US.CONTACT_1_MOBILE")}</p>
                            <p className="padding-top-zero">email: renata.nagy@filmpositive.hu</p> */}
                            <p className="padding-bottom-zero">{t("ABOUT_US.LASZLO_BEDERNA")}</p>
                            <p className="padding-top-zero padding-bottom-zero">{t("ABOUT_US.LASZLO_BEDERNA_MOBILE")}</p>
                            <p className="padding-top-zero">email: laszlo.bederna@filmpositive.hu</p>
                            
                        </div>
                    </Grow>
                   
                     <Grow in={true} {...(true ? { timeout: 3500 } : {})}>
                        <div className="inner-group inner-divider">
                            <h2><span ref={content4}>{t("ABOUT_US.LOCATION")}</span></h2>
                        </div>
                    </Grow>
                     <Grow in={true} {...(true ? { timeout: 4000 } : {})}>
                        <div className="inner-group map">
                            <Map/>
                        </div>
                    </Grow>
                </ContentInner>
            </ContentPanel>
        </ContentWrapper>
        </React.Fragment>
    )
}

export default AboutUsContent